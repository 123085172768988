/* ==========================================================================
   Forms
   ========================================================================== */

   form {
    margin: 0 0 5px 0;
    padding: 1em;
    background-color: $form-background-color;

    fieldset {
      margin-bottom: 5px;
      padding: 0;
      border-width: 0;
    }

    legend {
      display: block;
      width: 100%;
      margin-bottom: 5px * 2;
      *margin-left: -7px;
      padding: 0;
      color: $text-color;
      border: 0;
      white-space: normal;
    }

    p {
      margin-bottom: 5px / 2;
    }

    ul {
      list-style-type: none;
      margin: 0 0 5px 0;
      padding: 0;
    }

    br {
      display: none;
    }
  }

  label,
  input,
  button,
  select,
  textarea {
    vertical-align: baseline;
    *vertical-align: middle;
  }

  input,
  button,
  select,
  textarea {
    box-sizing: border-box;
    font-family: $sans-serif;
  }

  label {
    display: block;
    margin-bottom: 0.25em;
    color: $text-color;
    cursor: pointer;

    small {
      font-size: $type-size-6;
    }

    input,
    textarea,
    select {
      display: block;
    }
  }

  input,
  textarea,
  select {
    display: inline-block;
    width: 100%;
    padding: 0.25em;
    margin-bottom: 0.5em;
    color: $text-color;
    background-color: $background-color;
    border: $border-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }

  .input-mini {
    width: 60px;
  }

  .input-small {
    width: 90px;
  }

  input[type="image"],
  input[type="checkbox"],
  input[type="radio"] {
    width: auto;
    height: auto;
    padding: 0;
    margin: 3px 0;
    *margin-top: 0;
    line-height: normal;
    cursor: pointer;
    border-radius: 0;
    border: 0 \9;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *width: 13px;
    *height: 13px;
  }

  input[type="image"] {
    border: 0;
    box-shadow: none;
  }

  input[type="file"] {
    width: auto;
    padding: initial;
    line-height: initial;
    border: initial;
    background-color: transparent;
    background-color: initial;
    box-shadow: none;
  }

  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    width: auto;
    height: auto;
    cursor: pointer;
    *overflow: visible;
  }

  select,
  input[type="file"] {
    *margin-top: 4px;
  }

  select {
    width: auto;
    background-color: #fff;
  }

  select[multiple],
  select[size] {
    height: auto;
  }

  textarea {
    resize: vertical;
    height: auto;
    overflow: auto;
    vertical-align: top;
  }

  input[type="hidden"] {
    display: none;
  }

  .form {
    position: relative;
  }

  .radio,
  .checkbox {
    padding-left: 18px;
    font-weight: normal;
  }

  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    float: left;
    margin-left: -18px;
  }

  .radio.inline,
  .checkbox.inline {
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .radio.inline + .radio.inline,
  .checkbox.inline + .checkbox.inline {
    margin-left: 10px;
  }


  /*
     Disabled state
     ========================================================================== */

  input[disabled],
  select[disabled],
  textarea[disabled],
  input[readonly],
  select[readonly],
  textarea[readonly] {
    opacity: 0.5;
    cursor: not-allowed;
  }


  /*
     Focus & active state
     ========================================================================== */

  input:focus,
  textarea:focus {
    border-color: $primary-color;
    outline: 0;
    outline: thin dotted \9;
    box-shadow: inset 0 1px 3px rgba($text-color, 0.06), 0 0 5px rgba($primary-color, 0.7);
  }

  input[type="file"]:focus,
  input[type="radio"]:focus,
  input[type="checkbox"]:focus,
  select:focus {
    box-shadow: none;
  }


  /*
     Help text
     ========================================================================== */

  .help-block,
  .help-inline {
    color: $muted-text-color;
  }

  .help-block {
    display: block;
    margin-bottom: 1em;
    line-height: 1em;
  }

  .help-inline {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
  }

  /*
     .form-group
     ========================================================================== */

  .form-group {
    margin-bottom: 5px;
    padding: 0;
    border-width: 0;
  }


  /*
     .form-inline
     ========================================================================== */

  .form-inline input,
  .form-inline textarea,
  .form-inline select {
    display: inline-block;
    margin-bottom: 0;
  }

  .form-inline label {
    display: inline-block;
  }

  .form-inline .radio,
  .form-inline .checkbox,
  .form-inline .radio {
    padding-left: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    float: left;
    margin-left: 0;
    margin-right: 3px; }


  /*
     .form-search
     ========================================================================== */

  .form-search input,
  .form-search textarea,
  .form-search select {
    display: inline-block;
    margin-bottom: 0;
  }

  .form-search .search-query {
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 0;
    border-radius: 14px;
  }

  .form-search label {
    display: inline-block;
  }

  .form-search .radio,
  .form-search .checkbox,
  .form-inline .radio {
    padding-left: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-search .radio input[type="radio"],
  .form-search .checkbox input[type="checkbox"] {
    float: left;
    margin-left: 0;
    margin-right: 3px;
  }


  /*
     .form--loading
     ========================================================================== */

  .form--loading:before {
    content: '';
  }

  .form--loading .form__spinner {
    display: block;
  }

  .form:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10;
  }

  .form__spinner {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;
  }



  /*
     Google search form
     ========================================================================== */

  #goog-fixurl {
    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      li {
        list-style-type: none;
      }
    }
  }

  #goog-wm-qt {
    width: auto;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 8px 20px;
    display: inline-block;
    font-size: $type-size-6;
    background-color: #fff;
    color: #000;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: $border-color;
    border-radius: $border-radius;
  }

  #goog-wm-sb {
    @extend .btn;
  }