/* ==========================================================================
   Contrast skin
   ========================================================================== */

/* Colors */
$text-color: #000 !default;
$muted-text-color: $text-color !default;
$primary-color: #ff0000 !default;
$border-color: mix(#fff, $text-color, 75%) !default;
$footer-background-color: #000 !default;
$link-color: #0000ff !default;
$masthead-link-color: $text-color !default;
$masthead-link-color-hover: $text-color !default;
$navicon-link-color-hover: mix(#fff, $text-color, 80%) !default;

.page__content {

  .notice,
  .notice--primary,
  .notice--info,
  .notice--warning,
  .notice--success,
  .notice--danger {
    color: $text-color;
  }
}

.page__footer {
  color: #fff !important; // override
}

.page__footer-follow .social-icons .fa {
  color: inherit;
}