/* ==========================================================================
   NAVIGATION
   ========================================================================== */

/*
   Breadcrumb navigation links
   ========================================================================== */

   .breadcrumbs {
    @include clearfix;
    margin: 0 auto;
    max-width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    font-family: $sans-serif;
    -webkit-animation: $intro-transition;
            animation: $intro-transition;
    -webkit-animation-delay: 0.30s;
            animation-delay: 0.30s;

    @include breakpoint($large) {
      padding-left: 1em;
      padding-right: 1em;
    }

    @include breakpoint($x-large) {
      max-width: $x-large;
    }

    ol {
      padding: 0;
      list-style: none;
      font-size: $type-size-6;

      @include breakpoint($large) {
        float: right;
        width: span(10 of 12);
      }

      @include breakpoint($x-large) {
        padding-left: gutter(0.5 of 12);
      }
    }

    li {
      display: inline;
    }

    .current {
      font-weight: bold;
    }
  }


  /*
     Post pagination navigation links
     ========================================================================== */

  .pagination {
    @include clearfix();
    float: left;
    margin-top: 1em;
    padding-top: 1em;
    width: 100%;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-family: $sans-serif;
    }

    li {
      display: block;
      float: left;
      margin-left: -1px;

      a {
        display: block;
        margin-bottom: 0.25em;
        padding: 0.5em 1em;
        font-family: $sans-serif;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        color: $muted-text-color;
        border: 1px solid mix(#000, $border-color, 25%);
        border-radius: 0;

        &:hover {
          color: $link-color-hover;
        }

        &.current,
        &.current.disabled {
          color: #fff;
          background: $primary-color;
        }

        &.disabled {
          color: rgba($muted-text-color, 0.5);
          pointer-events: none;
          cursor: not-allowed;
        }
      }

      &:first-child {
        margin-left: 0;

        a {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
      }

      &:last-child {
        a {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }

    /* next/previous buttons */
    &--pager {
      display: block;
      padding: 1em 2em;
      float: left;
      width: 50%;
      font-family: $sans-serif;
      font-size: $type-size-5;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      color: $muted-text-color;
      border: 1px solid mix(#000, $border-color, 25%);
      border-radius: $border-radius;

      &:hover {
        @include yiq-contrasted($muted-text-color);
      }

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.disabled {
        color: rgba($muted-text-color, 0.5);
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  .page__content + .pagination,
  .page__meta + .pagination,
  .page__share + .pagination,
  .page__comments + .pagination {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid $border-color;
  }


  /*
     Priority plus navigation
     ========================================================================== */

  .greedy-nav {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
          align-items: center;
    background: $background-color;

    a {
      display: block;
      margin: 0 1rem;
      padding: 0.5rem 0;
      color: $masthead-link-color;
      text-decoration: none;

      &:hover {
        color: $masthead-link-color-hover;
      }

      &.site-title {
        margin-left: 0;
      }
    }

    button {
      padding: 0 0.5rem;
      align-self: stretch;
      border: 0;
      outline: none;
      color: #fff;
      background-color: $primary-color;
      cursor: pointer;
    }

    .visible-links {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: end;
       justify-content: flex-end;
      -webkit-box-flex: 1;
                  flex: 1;
      padding-right: 2rem;
      overflow: hidden;

      li {
        -webkit-box-flex: 0;
                    flex: none;

        &:last-child {
          a {
            margin-right: 0;
          }
        }
      }

      a {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 4px;
          background: $primary-color;
          width: 100%;
          -webkit-transition: $global-transition;
          transition: $global-transition;
          -webkit-transform: scaleX(0) translate3d(0, 0 , 0);
                  transform: scaleX(0) translate3d(0, 0 , 0); /* hide*/
        }

        &:hover:before {
          -webkit-transform: scaleX(1);
                  -ms-transform: scaleX(1);
              transform: scaleX(1); /* reveal*/
        }
      }
    }

    .hidden-links {
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: 15px;
      padding: 5px;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      background: $background-color;
      box-shadow: 0 2px 4px 0 rgba(#000, 0.16), 0 2px 10px 0 rgba(#000, 0.12);

      &.hidden {
        display: none;
      }

      a {
        margin: 0;
        padding: 10px 20px;
        font-size: $type-size-5;

        &:hover {
          color: $masthead-link-color-hover;
          background: $navicon-link-color-hover;
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: -11px;
        right: 10px;
        width: 0;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: $border-color transparent;
        display: block;
        z-index: 0;
      }

      &:after {
        content: "";
        position: absolute;
        top: -10px;
        right: 10px;
        width: 0;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: $background-color transparent;
        display: block;
        z-index: 1;
      }

      li {
        display: block;
        border-bottom: 1px solid $border-color;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }


  /*
     Navigation list
     ========================================================================== */

  .nav__list {
    margin-bottom: 1.5em;

    input[type="checkbox"],
    label {
      display: none;
    }

    @include breakpoint(max-width ($large - 1px)) {

      label {
        position: relative;
        display: inline-block;
        padding: 0.5em 2.5em 0.5em 1em;
        color: $gray;
        font-size: $type-size-6;
        font-weight: bold;
        border: 1px solid $light-gray;
        border-radius: $border-radius;
        z-index: 20;
        -webkit-transition: 0.2s ease-out;
        transition: 0.2s ease-out;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          position: absolute;
          right: 1em;
          top: 1.25em;
          width: 0.75em;
          height: 0.125em;
          line-height: 1;
          background-color: $gray;
          -webkit-transition: 0.2s ease-out;
          transition: 0.2s ease-out;
        }

        &:after {
          -webkit-transform: rotate(90deg);
                  -ms-transform: rotate(90deg);
              transform: rotate(90deg);
        }

        &:hover {
          color: #fff;
          border-color: $gray;
          background-color: mix(white, #000, 20%);

          &:before,
          &:after {
            background-color: #fff;
          }
        }
      }

      /* selected*/
      input:checked + label {
        color: white;
        background-color: mix(white, #000, 20%);

        &:before,
        &:after {
          background-color: #fff;
        }
      }

      /* on hover show expand*/
      label:hover:after {
        -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
            transform: rotate(90deg);
      }

      input:checked + label:hover:after {
        -webkit-transform: rotate(0);
                -ms-transform: rotate(0);
            transform: rotate(0);
      }

      ul {
        margin-bottom: 1em;
      }

      a {
        display: block;
        padding: 0.25em 0;

        @include breakpoint($large) {
          padding-top: 0.125em;
          padding-bottom: 0.125em;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .nav__list .nav__items {
    margin: 0;
    font-size: 1.25rem;

    a {
      color: inherit;
    }

    .active {
      margin-left: -0.5em;
      padding-left: 0.5em;
      padding-right: 0.5em;
      font-weight: bold;
    }

    @include breakpoint(max-width ($large - 1px)) {
      position: relative;
      max-height: 0;
      opacity: 0%;
      overflow: hidden;
      z-index: 10;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      -webkit-transform: translate(0, 10%);
              -ms-transform: translate(0, 10%);
          transform: translate(0, 10%);
    }
  }

  @include breakpoint(max-width ($large - 1px)) {
    .nav__list input:checked ~ .nav__items {
      -webkit-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      max-height: 9999px; /* exaggerate max-height to accommodate tall lists*/
      overflow: visible;
      opacity: 1;
      margin-top: 1em;
      -webkit-transform: translate(0, 0);
              -ms-transform: translate(0, 0);
          transform: translate(0, 0);
    }
  }

  .nav__title {
    margin: 0;
    padding: 0.5rem 1rem;
    font-family: $sans-serif-narrow;
    font-size: $type-size-5;
    font-weight: bold;
  }

  .nav__sub-title {
    display: block;
    margin: 0.5rem 0;
    padding: 0.5rem 0;
    font-family: $sans-serif-narrow;
    font-size: $type-size-6;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid $border-color;
  }


  /*
     Table of contents navigation
     ========================================================================== */

  .toc {
    font-family: $sans-serif-narrow;
    color: $gray;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: $background-color;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    .nav__title {
      color: #fff;
      font-size: $type-size-6;
      background: $primary-color;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  .toc__menu {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    font-size: 0.8rem;

    a {
      display: block;
      padding: 0.5rem 1rem;
      color: $muted-text-color;
      font-size: $type-size-7;
      font-weight: bold;
      line-height: 1.5;
      border-bottom: 1px solid $border-color;

      &:hover {
        color: $text-color;
      }
    }

    > li:last-child {
      a {
        border-bottom: none;
      }
    }

    li ul > li a {
      padding-left: 1.75rem;
      font-weight: normal;
    }

    /* hide sub sub links on small screens*/
    li > ul li {
      display: none;

      @include breakpoint($medium) {
        display: block;
      }
    }
  }
